<template>
  <div class="content" style="margin-left: 57px; margin-bottom: 70px;">

    <transition name="modal" v-if="isVideoModal">
      <div  @click="isVideoModal = false" class="modal-mask"  style="overflow-y: scroll; padding-top: 100px; background-color: rgba(0, 0, 0, .8)!important; ">
        <div class="modal-container" style="width: 900px !important; padding:0!important;; max-height: 506px">

          <div >
            <iframe
              allowfullscreen="true"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              width="900" height="506"
              frameborder="0"
              scrolling="no"
              :src="'https://api.bionluk.com/general/video/' +  '10727050'+'x'+ '10727050' +'/custom_video/'"
            >
            </iframe>
          </div>

        </div>
      </div>
    </transition>

    <transition v-if="isModal" name="modal">
      <div class="modal-mask"  style="overflow-y: scroll; padding-top: 100px; ">
        <div class="modal-container" style="width: 660px !important;overflow: scroll;max-height: 100%">
          <div style="float: right; padding-right: 10px;">
            <img @click="isModal = false" style=" cursor:pointer;width: 20px; height: 20px;" :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
          </div>


          <div style="text-align: center; width: 550px; margin: 0 auto 60px;">
            <p class="content-header-text">
              Yeni Liste <span style="font-weight: 600">Oluştur</span>
            </p>
            <p style="font-size: 18px; color: #768191; line-height: 1.3; font-weight: normal; margin-top: 20px">
              Beğendiğin freelancerlara ve onların iş ilanlarına kolayca ulaşabileceğin listeler oluştur.

            </p>

            <div style="margin-top:50px; display: flex; flex-direction: column; align-items: center; justify-content: center">
              <custom-input label="Liste Adı"
                            place-holder="Örn: SEO Uzmanları"
                            inputType="text"
                            v-model="modalListName"
                            maxlength="30"
                            style="width: 366px;"/>

              <custom-button @click="createList" :disabled="buttonLoading" :button-type="'green'" style="margin-top: 50px; width: 164px;">Kaydet</custom-button>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div class="content-header">
      <div style="display: flex">
        <div>
          <p class="content-header-text">
            Listelerim
          </p>
          <p  v-if="lists && lists.length > 0" style="font-size: 18px; color: #6a7685; line-height: 1.44;font-weight: 400; margin-top: 10px">
            Beğendiğin freelancerlara ve onların iş ilanlarına kolayca ulaşabileceğin listeler oluştur. Bu özelliği nasıl kullanabileceğini <span @click="openVideoModal()" class="hover-gg">izlemek için tıkla!</span>
          </p>
        </div>
        <div>
          <custom-button
            button-type="greenPlus"
            style="border-radius: 4px; height: 40px; padding-bottom: 2px; font-size: 14px; font-weight: 600; margin-left: 20px; margin-top: 30px; width: 164px; "
            v-if="pageLoaded && lists && lists.length > 0"
                  class="cuper-red-button"
                  @click="openModal"
                 >
            Yeni Liste Oluştur
          </custom-button>
        </div>
      </div>
    </div>
    <loader v-if="!pageLoaded"/>
    <div v-else style="padding-bottom: 50px;">
      <div class="list-row-container">
        <div v-if="!lists || !lists.length" class="ee">

          <img style="width: 302px; margin-top: 70px;" src="https://gcdn.bionluk.com/uploads/message/b7de1367-db4c-4667-adcb-7ee6ef7487ce.png" />
          <p class="ee-title">Hayalindeki Listeyi Oluştur</p>
          <p class="ee-text">
            Beğendiğin freelancerlara ve onların iş ilanlarına kolayca ulaşabileceğin listeler oluştur. Gelecekte birlikte harika işler çıkartabilirsiniz. 🧙
          </p>
          <p class="ee-text" style="margin-top: 5px">Bu özelliği nasıl kullanabileceğini <span @click="openVideoModal()" class="hover-gg">izlemek için tıkla!</span></p>
          <custom-button button-type="green" style="margin-top: 40px" @click="isModal = true">Yeni Liste Oluştur</custom-button>
        </div>
        <div v-else v-for="listRow in lists" class="list-row">
          <div v-if="listRow.images.length" @click="$router.push('/panel/listelerim/'+listRow.id)" class="avatars">
            <img class="avatar" :src="image" v-for="image in listRow.images">
            <div v-if="(listRow.counts.freelancer + listRow.counts.gig) - listRow.images.length > 2" class="empty-plus">+{{ ((listRow.counts.freelancer + listRow.counts.gig) - 3) > 99 ? 99 : ((listRow.counts.freelancer + listRow.counts.gig) - 3)}}</div>
          </div>
          <div @click="$router.push('/panel/listelerim/'+listRow.id)" class="titles" :style="listRow.images.length ? 'width: 500px;' : '  width: 790px;'">
            <p class="list-title">{{listRow.title}}</p>
            <p class="list-info">{{listRow.counts.gig}} İş İlanı • {{listRow.counts.freelancer}} Freelancer</p>
          </div>
          <div class="icons">
            <div @click="deleteList(listRow.id)" class="trash-icon-container">
              <img class="trash-icon" src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)"/>
            </div>
          </div>
        </div>
      </div>
      <div class="gig-list-container">
        <single-gig :impression-u-u-i-d="impressionUUID" :item="fav"  v-for="(fav, findex) in favs" class="gig-list-gig" :key="findex"/>
      </div>

      <div v-if="seemorediv && !seemoreLoader" @click="retrieveFavs()"
           style="cursor: pointer; margin-top:10px;font-size: 16px; font-weight: 600;text-align: center; color: #2d3640;">
        Daha fazla gör +
      </div>
      <loader v-else-if="seemoreLoader"></loader>


    </div>

  </div>

</template>

<script>

import CustomInput from "@/pages/components/CustomInput";
import CustomButton from "@/pages/components/CustomButton";
import singleGig from "@/pages/components/singleGig";
  export default {
    components: {singleGig, CustomInput, CustomButton},
    name: "src-pages-body-workstation-favorites-v2",
    data() {
      return {

        isVideoModal:false,
        isModal:false,
        modalListName:'',
        buttonLoading:false,
        lists:[],

        impressionUUID:null,
        pageLoaded: false,
        offset: 0,
        favs: [],
        totalCount: 0,
        seemorediv: null,
        seemoreLoader: false,
        limit: 10,
        swiperOption: {
          notNextTick: true,
          direction: 'horizontal',
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',
          slidesPerView: 1,
          observeParents: true
        },
      }
    },

    methods: {

      openVideoModal(){
        this.isVideoModal = true;
        this.Helper.trackEvents.customEvent("custom_video_view", {title:'listelerim', page:this.$store.state.componentMap.page});

      },
      openModal(){
        let data = {
          type: 'create',
        };
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.ADD_LIST, info: {data: data}});
      },

      getLists(){
        this.api.general.crudList('get_all', '', null, null, this.$Progress)
          .then(({data}) => {
            let result = data;
            this.lists = result.data.lists
            this.pageLoaded = true;
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.pageLoaded = true;
          })

      },

      deleteList(id){
        if(this.buttonLoading) {return false}
        this.buttonLoading = true;
        let alert = "Listeyi silmen halinde, liste içindeki tüm kayıtlar da silinir. Onaylıyor musun?";
        if (confirm(alert) === true ) {
          this.api.general.crudList('delete', '', id, null, this.$Progress)
            .then(({data}) => {
              let result = data;
              if(result.success){
                this.isModal = false;
                this.pageLoaded = true;
                this.buttonLoading = false;
                this.modalListName = '';
                this.getLists();
              } else {
                this.buttonLoading = false;
                this.$toasted.global.errorToast({description: result.message});
              }
            })
            .catch(err => {
              this.buttonLoading = false;
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            })
        } else {
          this.buttonLoading = false;
        }

      },
      createList(){
        if(this.buttonLoading) {return false}
        this.buttonLoading = true;
        this.api.general.crudList('create', this.modalListName, null, null, this.$Progress)
          .then(({data}) => {
            let result = data;
            if(result.success){
              this.isModal = false;
              this.pageLoaded = true;
              this.buttonLoading = false;
              this.modalListName = '';
              this.getLists();
              window.scrollTo(0, 0);
            } else {
              this.buttonLoading = false;
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.buttonLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })

      },
      goToGig(slug) {
        this.$router.push(slug);
      },
      retrieveFavs() {
        this.seemoreLoader = true;
        this.api.general.getLikedGigs(this.limit, this.offset, this.user.username)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.pageLoaded = true;
              this.seemoreLoader = false;

              if(result.data.likes.length > 0){

                this.impressionUUID = this.Helper.generateUUID();

                let trackGigObj = [];
                result.data.likes.forEach((gig, gigdex) => {
                  trackGigObj.push({
                    gig_id:gig.id,
                    seller_id:gig.seller_id,
                    order_id: gigdex + 1
                  })
                });
                if(trackGigObj.length > 0){
                  let trackingProps = {
                    page:'workstationfavorites',
                    placement:'default',
                    info: trackGigObj,
                    title: this.impressionUUID
                  };
                 this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);
                }
              }

              this.offset += this.limit;
              this.seemorediv = result.data.seemorediv;
              this.favs = this.favs.concat(result.data.likes);
              this.totalCount = result.data.likeCount;

            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      removeFavItem(gigID) {
        this.api.general.updateLikeStatus(this.Constants.FAV_TYPES.GIG, this.Constants.FAV_STATUS.DELETE, gigID)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.favs = this.favs.filter(function (fav) {
                return fav.uuid !== gigID
              });
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      }
    },
    beforeDestroy() {
      this.EventBus.$off('newList');
    },

    created() {
      this.impressionUUID = this.Helper.generateUUID();
      this.getLists();
      window.scrollTo(0, 0);

      this.EventBus.$on('newList', payload => {
        this.pageLoaded = false;
        this.getLists();
        window.scrollTo(0, 0);
      });


    }
  }
</script>

<style scoped lang="scss">

.hover-gg{
  color: #ff5364;
  font-weight: 600;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}

.list-row-container{
  margin-top: 50px;
  width: 100%;

  .ee{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   .ee-title{
     margin-top: 50px;
     font-size: 26px;
     font-weight: 600;
     color: #2d3640;
   }
    .ee-text{
      width: 547px;
      margin-top: 20px;
      font-size: 16px;
      font-weight: 300;

      line-height: 1.88;
      text-align: center;

      color: #5e6b79;
    }
  }
  .list-row{
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 0 0 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;
    height: 120px;
    &:hover{
      box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    }


    .avatars{
      cursor: pointer;
      margin-left: 50px;
      display: flex;
      width: 230px;

      .avatar{
        background: white;
        margin-right: -20px;
        border-radius: 50%;
        height: 57px;
        width: 57px;
        border:2px solid #eff3f8;
      }

      .empty-plus{
        height: 53px;
        width: 57px;
        border-radius: 50%;
        background-color: #2d3640;
        border:2px solid #eff3f8;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size:24px;
        padding-bottom: 3px;
        font-weight: bold;
      }

    }

    .titles{
      cursor: pointer;
      margin-left: 35px;
      display: flex;
      flex-direction: column;
      justify-content: center;
     .list-title{
       font-size: 18px;
       font-weight: 500;
       line-height: 1.11;
       color: #2d3640;
     }
     .list-info{
       margin-top: 5px;
       font-size: 14px;
       font-style: italic;
       line-height: 1.43;
       color: #8b95a1;
      }
    }

    .icons{

      .trash-icon-container {
        cursor: pointer;
        &:hover{
          opacity: 0.8;
        }
        .trash-icon /deep/{
          width: 20px;
          height: 20px;
          path {
            fill: #bfc8d2
          }
        }
      }
    }
  }
}


.content-header-text{
  font-size: 34px;
  font-weight: 600;
  color: #3c4654;
}
.content-header{
  margin-top: 31px;
  margin-bottom: 13px;
  width: 100%;
  align-items: center;
}


.gig-list-container {

  position: relative;
  display: flex;
  width: 900px;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-left: 50px;


  .gig-list-gig{
    margin-bottom: 40px;
    margin-left: 24px;
    &:nth-child(3n+1) {
      margin-left: 0;
    }
  }

  &:hover {
    .swiper-button-prev, .swiper-button-next {
      visibility: visible;
    }
  }
}

  .gigContainer {
    padding-bottom: 10px;
    width: 900px;
    margin: 0 auto;
    padding-top: 20px;
  }

  .gig-main-div {
    display: inline-block;
    margin-bottom: 40px;
    margin-left: 30px;
    width: 263px;
    height: 296px;
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    vertical-align: top;
  }

  .a3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    margin-top: 10px;
  }


  .swiper-button-prev {
    background-image: url(https://gcdn.bionluk.com/site/icon/icon_video_ok_left.svg);
    width: 36px;
    height: 36px;
    background-color: #ffffff;
    border: solid 1px #dfe4ec;
    background-size: 8px 8px;
    left: 0;
    opacity: 1;
    border-left: none;
  }

  .swiper-button-next {
    background-image: url(https://gcdn.bionluk.com/site/icon/icon_video_ok_right.svg);
    width: 36px;
    height: 36px;
    background-color: #ffffff;
    border: solid 1px #dfe4ec;
    background-size: 8px 8px;
    right: 0;
    opacity: 1;
    border-right: none;
  }

  .swiper-button-disabled {
    opacity: 0.7;
    pointer-events: all !important;
  }

  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-to {
    animation: bounce-out .0s;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0.2);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes bounce-out {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }


</style>
